html {
  min-height: 100%;
}

body {
  background-image: url('./assets/images/mustang_old_black.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  min-height: 100vh;
}

#root {
  width: 100%;
  min-height: 100%;
  background-color: rgba(0,0,0,.75);

  display: flex;
  align-items: center;
}